exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-work-index-js": () => import("./../../../src/pages/work/index.js" /* webpackChunkName: "component---src-pages-work-index-js" */),
  "component---src-pages-work-mdx-frontmatter-slug-js-content-file-path-home-runner-work-arthur-wilton-portfolio-arthur-wilton-portfolio-work-acnh-automator-index-mdx": () => import("./../../../src/pages/work/{Mdx.frontmatter__slug}.js?__contentFilePath=/home/runner/work/arthur-wilton-portfolio/arthur-wilton-portfolio/work/acnh-automator/index.mdx" /* webpackChunkName: "component---src-pages-work-mdx-frontmatter-slug-js-content-file-path-home-runner-work-arthur-wilton-portfolio-arthur-wilton-portfolio-work-acnh-automator-index-mdx" */),
  "component---src-pages-work-mdx-frontmatter-slug-js-content-file-path-home-runner-work-arthur-wilton-portfolio-arthur-wilton-portfolio-work-creative-projects-index-mdx": () => import("./../../../src/pages/work/{Mdx.frontmatter__slug}.js?__contentFilePath=/home/runner/work/arthur-wilton-portfolio/arthur-wilton-portfolio/work/creative-projects/index.mdx" /* webpackChunkName: "component---src-pages-work-mdx-frontmatter-slug-js-content-file-path-home-runner-work-arthur-wilton-portfolio-arthur-wilton-portfolio-work-creative-projects-index-mdx" */),
  "component---src-pages-work-mdx-frontmatter-slug-js-content-file-path-home-runner-work-arthur-wilton-portfolio-arthur-wilton-portfolio-work-fcpx-marker-tool-index-mdx": () => import("./../../../src/pages/work/{Mdx.frontmatter__slug}.js?__contentFilePath=/home/runner/work/arthur-wilton-portfolio/arthur-wilton-portfolio/work/fcpx-marker-tool/index.mdx" /* webpackChunkName: "component---src-pages-work-mdx-frontmatter-slug-js-content-file-path-home-runner-work-arthur-wilton-portfolio-arthur-wilton-portfolio-work-fcpx-marker-tool-index-mdx" */),
  "component---src-pages-work-mdx-frontmatter-slug-js-content-file-path-home-runner-work-arthur-wilton-portfolio-arthur-wilton-portfolio-work-personal-inventory-manager-index-mdx": () => import("./../../../src/pages/work/{Mdx.frontmatter__slug}.js?__contentFilePath=/home/runner/work/arthur-wilton-portfolio/arthur-wilton-portfolio/work/personal-inventory-manager/index.mdx" /* webpackChunkName: "component---src-pages-work-mdx-frontmatter-slug-js-content-file-path-home-runner-work-arthur-wilton-portfolio-arthur-wilton-portfolio-work-personal-inventory-manager-index-mdx" */),
  "component---src-pages-work-mdx-frontmatter-slug-js-content-file-path-home-runner-work-arthur-wilton-portfolio-arthur-wilton-portfolio-work-video-projects-index-mdx": () => import("./../../../src/pages/work/{Mdx.frontmatter__slug}.js?__contentFilePath=/home/runner/work/arthur-wilton-portfolio/arthur-wilton-portfolio/work/video-projects/index.mdx" /* webpackChunkName: "component---src-pages-work-mdx-frontmatter-slug-js-content-file-path-home-runner-work-arthur-wilton-portfolio-arthur-wilton-portfolio-work-video-projects-index-mdx" */)
}

