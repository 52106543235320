module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Arthur Wilton Portfolio","short_name":"Arthur Wilton","start_url":"/","background_color":"#f0f0f0","display":"minimal-ui","icon":"src/content/icons/favicon.svg","legacy":false,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"b3c068423243273a0cdba460a611d837"},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":900,"quality":50,"linkImagesToOriginal":false,"showCaptions":["title"],"withWebp":true,"withAvif":true},
    },{
      plugin: require('../node_modules/gatsby-omni-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"enableListener":true,"preconnect":["https://fonts.googleapis.com","https://fonts.gstatic.com","https://use.typekit.net","https://p.typekit.net"],"web":[{"name":"Montserrat","file":"https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800&display=swap"},{"name":"Lato","file":"https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&display=swap"},{"name":["europa","gill-sans-nova"],"file":"https://use.typekit.net/phy7oeu.css"}]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
